import React from 'react';

import Layout from './src/components/layout/layout'
import Header from "./src/components/layout/header"
import "./src/components/layout/layout.scss"

export const wrapPageElement = ({ element, props }) => (
    <Layout {...props}>
        <Header siteTitle="ARMON SEMADENI<br>ARCHITEKTEN" />
        {element}
    </Layout>
)

// export const registerServiceWorker = () => true
