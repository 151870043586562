import React from "react"
import posed from 'react-pose';
// import { motion } from "framer-motion"
import { TransitionState } from "gatsby-plugin-transition-link";

const transitionX = {
  x: {
    ease: 'easeOut', 
    duration: 600 
  },
}

const transitionY = {
  y: {
    ease: 'easeOut', 
    duration: 600 
  },
}



const animationDefault = {
  exiting:  { 
    x: "-100%",
    transition: transitionX,
    opacity: "1 !important"
  },
  exited:   { 
    x: "100%" ,
    transition: transitionX,
    opacity: "1 !important"
  },
  entering: { 
    x: "0",
    transition: transitionX,
    // transition: transitionX,
    opacity: "1 !important"
  },
  entered:  {
    x: "0",
    transition: transitionX,
    opacity: "1 !important"
  },
}


// const animationDefault = {
//   exiting:  { 
//     // x: "-100%",
//     x: "0",
//     transition: transitionX,
//     opacity: "1 !important"
//   },
//   exited:   { 
//     // x: "0",
//     x: "-100%" ,
//     transition: transitionX,
//     opacity: "1 !important"
//   },
//   entering: { 
//     x: "100%",
//     // x: "0",
//     transition: transitionX,
//     opacity: "1 !important"
//   },
//   entered:  {
//     x: "0",
//     transition: transitionX,
//     opacity: "1 !important"
//   },
// }
const FadingContent = posed.div({
  ...animationDefault,

  exitingNext:  { 
    y: "-100%",
    transition: transitionY
  },
  exitedNext:   { 
    y: "100%",
    transition: transitionY
  },
  enteringNext: { 
    y: "0",
    transition: transitionY
  },
  enteredNext:  { 
    y: "0",
    transition: transitionY
  },
})

console.log(FadingContent) 
// const FadingContent = posed.div({
//   ...animationDefault,

//   exitingNext:  { 
//     y: "-100%",
//     transition: transitionY
//   },
//   exitedNext:   { 
//     y: "0" ,
//     transition: transitionY
//   },
//   enteringNext: { 
//     y: "100%",
//     transition: transitionY
//   },
//   enteredNext:  { 
//     y: "0",
//     transition: transitionY
//   },
// })

const Transition = (props) => {

  const children = props.children

  // return(
  //   <div>
  //     {children}
  //   </div>
  // )
  return(
    <TransitionState 
     
    >
      {
        (props) => {

          let transitionStatus = props.transitionStatus

          if(props.entry.state && props.entry.state.previousPost){

        
            switch(props.transitionStatus) {
              case 'exiting':
                transitionStatus = 'exitingNext'
                break
              case 'exited':
                transitionStatus = 'exitedNext'
                break
              case 'entering':
                transitionStatus = 'enteringNext'
                break
              case 'entered':
                transitionStatus = 'enteredNext'
                break
              default:
                transitionStatus = 'enteredNext'
                break
            }
          }

          return(
            <FadingContent pose={transitionStatus}>
              {children}
            </FadingContent>
          )
        }
      }
    </TransitionState>
  )
}

// export default Div
export default Transition

const TRANSITION_LENGTH = 0.6
// const TRANSITION_LENGTH = 0.6

const exitTransition = {
  delay: 0,
  // delay: TRANSITION_LENGTH, // Take 1.5 seconds to leave
  length: TRANSITION_LENGTH, // Take 1.5 seconds to leave
  // trigger: ({ exit, node}) => {
    // console.log('we are exciting ',exit, node)
  // }
}

const entryTransition = {
  // delay: 0, // Wait 1.5 seconds before entering
  // length: TRANSITION_LENGTH
  // trigger: ({ exit, node}) => {
  //   console.log('we are entering ',exit, node)
  // }
}

export { exitTransition, entryTransition }


