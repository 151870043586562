import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// import { exitTransition, entryTransition} from '../transition/--transition-motion'
import { exitTransition, entryTransition} from '../transition/transition'
import TransitionLink from 'gatsby-plugin-transition-link'
// import DefinedLink from './link'

// import "./header.scss"
import style from "./header.module.scss"

import ContextConsumer from "./context"

const Header = () => {

  const content = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wpcontent{
        categories{
          nodes{
            name
            slug
          }
        }

        pageBy(uri: "buero") {
          id
          buerofields {
            phone
            email
            address
            city
          }
        }
      }
    }
  `)
  
  return(
    <header>
      <nav>
        <div>
          <h1>
            <TransitionLink 
            to="/"
            exit={exitTransition}
            entry={entryTransition}
            dangerouslySetInnerHTML={{__html: "Armon Semadeni<br>Architekten".toUpperCase() }}
            />
          </h1>
        </div>
        <ContextConsumer>
          {({ data, set }) => (
            <div 
              className={style.menu__open} 
              onClick={() => set({ menuOpen: !data.menuOpen })} 
              onKeyDown={() => set({ menuOpen: !data.menuOpen })} 
              role="button" 
              tabIndex={0}
              aria-label="Hamburger"
              >
              <div className={`${style.menu__button} ${data.menuOpen ? style.open : ""}`}>
                <div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>  
          )}
        </ContextConsumer>
 
        <ContextConsumer>
          {({ data, set }) => (
            <Menu content={content} menuOpen={data.menuOpen} handleNavigation={() => set({ menuOpen: !data.menuOpen })}/>  
          )}
        </ContextConsumer>
      </nav>
    </header>
  )
}

const Menu = ({ content, menuOpen, handleNavigation }) => (
  <div className={`${style.menu} ${menuOpen ? style.open : ""}`} onClick={handleNavigation} onKeyDown={handleNavigation} role="button" tabIndex={0}>
    <div className={style.menu__close}></div>
    <div className={style.menu__wrapper}>
      {
        content.wpcontent.categories.nodes.map((category, i) => {
          if(category.slug !== "uncategorized"){
            return(
              <div className={`${style.menu__item} ${i === 0 ? style.first : ""}`} key={i}>
              
                <TransitionLink 
                  mode="immediate"
                  to={`/${category.slug}/`}
                  exit={exitTransition}
                  entry={entryTransition}
                  state={{ 
                    filter: category.slug
                  }}
                  >
                  {category.name}
                </TransitionLink>
              </div>
            )
          }else{
            return false;
          }
        })  
      }
      <div className={`${style.menu__item}`}>
        <TransitionLink 
          mode="immediate"
          to={`/studien/`}
          exit={exitTransition}
          entry={entryTransition}
          >
          Studien
        </TransitionLink>
      </div>
      <div className={style.menu__item__spacer}>

      </div>
      <div className={style.menu__item}>
        <TransitionLink 
          to="/buero"
          exit={exitTransition}
          entry={entryTransition}>
          Büro
        </TransitionLink>
      </div>
      <div className={`${style.menu__item} ${style.last}`}>
        <a href={`tel:+${content.wpcontent.pageBy.buerofields.phone}`}>{content.wpcontent.pageBy.buerofields.phone}</a><br />
        <a href={`mailto:${content.wpcontent.pageBy.buerofields.email}`}>{content.wpcontent.pageBy.buerofields.email}</a><br />
        <address>
          {content.wpcontent.pageBy.buerofields.address}<br />
          {content.wpcontent.pageBy.buerofields.city}
        </address>
      </div>
    </div>
  </div>
)

export default Header
